.person{
    position: relative;
    width: 4.3vw;
    overflow: initial;
    float: initial;
    padding: 0.35vw;
    box-sizing: border-box;
    margin-bottom: 2.5vh;
    text-shadow: 1px 1px 2px black;
}
.person .profile-img{
    width: 100%;
    text-align: center;
    float: left;
    box-sizing: border-box;
    box-shadow: 0 0.5vh 1vh 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.person .profile-img-star{
    position: absolute;
    top: -0.5vh;
    right: -0.5vh;
    width: 3vh;
    box-sizing: border-box;
}

.person .profile-img-star img{
    width: 100%;
}

.person .profile-img-star .event-count{
    position: absolute;
    left: 0;
    width: 100%;
    line-height: 3vh;
    font-size: 1.3vh;
    color: black;
}

.person.circle .profile-img{
    text-align: center;
    float: left;
    border-radius: 50%;
    border-top-width: 0.4vh;
    border-bottom-width: 0.4vh;
    border-right-width: 0.4vh;
    border-left-width: 0.4vh;
    border-color: white;
    border-style: solid;
}

.person .profile{
    position: relative;
}

.loading{
    border-radius: 360px;
    -webkit-animation-name: Rotate;
    -webkit-animation-duration: 1s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-name: Rotate;
    -moz-animation-duration: 1s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -ms-animation-name: Rotate;
    -ms-animation-duration: 1s;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
    animation-duration: 1s;
    animation-name: Rotate;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}
@keyframes Rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
@-webkit-keyframes Rotate {
    from {
      -webkit-transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  @-moz-keyframes Rotate {
    from {
      -moz-transform: rotate(0deg);
    }
    to {
      -moz-transform: rotate(360deg);
    }
  }
  @-ms-keyframes Rotate {
    from {
      -ms-transform: rotate(0deg);
    }
    to {
      -ms-transform: rotate(360deg);
    }
  }


.flytext{
    position: absolute;
    font-size: 0.4vw;
    line-height: 0.4vw;
    bottom: 0vh;
    text-align: center;
    left: -20%;
    right: -20%;
}
.flytext-title{
    position: absolute;
    font-size: 0.4vw;
    line-height: 0.4vw;
    bottom: -1vh;
    text-align: center;
    left: -20%;
    right: -20%;
}
.flytext-phoneNumber{
    position: absolute;
    font-size: 0.4vw;
    line-height: 0.4vw;
    bottom: -2vh;
    text-align: center;
    left: -20%;
    right: -20%;
}

.flytext-plans{
    position: absolute;
    top: 0.2vw;
    right: -0.15vw;
    text-align: right;
}
.flytext-plans .plan{
    display: block;
    background: rgba(0,0,0,0.7);
    padding: 0.1vw 0.2vw 0.1vw 0.2vw;
    border-radius: 2px;
    margin-top: 0.05vw;
    font-size: 0.4vw;
    vertical-align: baseline;
}

.flytext-plans .dot{
    display: inline-block;
    vertical-align: baseline;
    padding: 0;
    height: 0.3vw;
    width: 0.3vw;
    margin: 0 0 0 0.2vw;
    border-radius: 100%;
}
.flytext-plans .legend:before{
    left: 0;
    top: 0;
}

.person .radial{
    left: -12%;
    top: -38%;
    position: absolute;
    width: 200%;
    height: 200%;
    z-index: 100;
    border-radius: none;
}

.radial img{
    width: 100%;
}

.person .radial button,a{
    background: none;
    border: none;
    color: white;
    font-family: inherit;
    font-size: inherit;
}

.person .radial .checkin{
    position: absolute;
    width: 115%;
    right: -39%;
    top: -19%;
    z-index: 1;
}
.person .radial .checkin button{
    position: absolute;
    top: 0;
    right: 0;
    width: 80%;
    height: 60%;
    padding-right: 25%;
    text-shadow: 1px 1px 2px black;
    text-align: right;
}

.person .radial .checkout{
    position: absolute;
    width: 115%;
    right: -62%;
    top: 12%;
    z-index: 2;
}

.person .radial .checkout button{
    position: absolute;
    top: 0;
    right: 0;
    width: 90%;
    height: 67%;
    padding-right: 30%;
    text-shadow: 1px 1px 2px black;
    text-align: right;
}

.person .radial .plan{
    position: absolute;
    width: 115%;
    right: -48%;
    top: 52%;
    z-index: 2;
}

.person .radial .plan button{
    position: absolute;
    top: 30%;
    right: 0;
    width: 80%;
    height: 60%;
    padding-right: 30%;
    text-shadow: 1px 1px 2px black;
    text-align: right;
}

.person .radial.inversed .checkin{
    position: absolute;
    width: 115%;
    right: 59%;
    top: -19%;
}
.person .radial.inversed  .checkin button{
    position: absolute;
    top: 0;
    right: 20%;
    width: 80%;
    height: 60%;
    padding-left: 30%;
    padding-right: 0;
    text-align: left;
}

.person .radial.inversed .checkout{
    position: absolute;
    width: 115%;
    right: 82%;
    top: 12%;
}

.person .radial.inversed  .checkout button{
    position: absolute;
    top: 0;
    right: 20%;
    width: 90%;
    height: 67%;
    padding-left: 40%;
    padding-right: 0;
    text-align: left;
}

.person .radial.inversed  .plan{
    position: absolute;
    width: 115%;
    right: 69%;
    top: 51%;
}

.person .radial .ill{
    position: absolute;
    width: 119%;
    right: -21%;
    top: 56.5%;
    z-index: 1;
}

.person .radial .ill button{
    position: absolute;
    top: 54%;
    right: 0;
    width: 90%;
    height: 43%;
    padding-left: 0;
    padding-right: 28%;
    text-align: right;
    text-shadow: 1px 1px 2px black;
}

.person .radial.inversed .ill{
    position: absolute;
    width: 119%;
    right: 39.5%;
    top: 56.5%;
}

.person .radial.inversed .ill button{
    position: absolute;
    top: 54%;
    right: 10%;
    width: 90%;
    height: 43%;
    padding-left: 28%;
    padding-right: 0;
    text-align: left;
}

.person .radial .plan a{
    position: absolute;
    bottom: 0;
    right: 0;
    width: 80%;
    height: 70%;
}
.person .radial .plan a button{
    width: 100%;
    height: 100%;
    text-shadow: 1px 1px 2px black;
    padding-right: 35%;
    text-align: right;
}

.person .radial.inversed .plan button{
    text-align: left;
    padding-left: 10%;
    padding-right: 0;
}
.person .radial.inversed .plan a{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 80%;
    height: 70%;
}

.person .radial button{
    font-size: 1.3vh;
    line-height: 1.3vh;
}

.person .birthday{
    width: 2.5vh;
    height: 2.5vh;
    position: absolute;
    right: 0;
    bottom: 0;
}